<template>
  <div
    class="game-item"
    :class="{
      maintain: item.maintain || item?.tags?.toLowerCase() === 'maintenance',
      background: jackpotNumber || showName
    }"
    @click="onClickGame()"
  >
    <CommonBaseImg
      class="game-item__image"
      :src="item.img"
      :alt="item.name"
      :border-radius="`${radius ? radius : '16'}`"
      lazy
    />
    <div v-if="isPlayNow">
      <div v-if="jackpotNumber" class="game-item__jackpot">
        <AnimateNumber :number="jackpotNumber" :show-coin="showCoin" />
      </div>
      <div class="game-item__overlay">
        <div class="inner">
          <button>{{ textPlay }}</button>
          <p>Chơi thử</p>
        </div>
      </div>
      <div v-if="showName" class="game-item__game-name">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <CommonBaseImg
      v-if="item.maintain || item?.tags?.toLowerCase() === 'maintenance'"
      class="game-item__maintain"
      :src="PATH_IMAGES + 'tag-maintain.svg'"
    />
    <CommonBaseImg
      v-else-if="getThumbImage(item)"
      class="game-item__maintain"
      :class="[item?.tags?.toLowerCase()]"
      :src="getThumbImage(item)"
      :alt="`tags ${item.tags}`"
    />
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import useNavigationGame from '~/composables/useNavigationGame'
import AnimateNumber from '~/components/common/animate-number.vue'
import { IGameItem } from '~/types/common'
import { PATH_IMAGES } from '~/constants/path'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { jackpot } = storeToRefs(store)
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const props = withDefaults(
  defineProps<{
    item: IGameItem
    showCoin?: boolean
    showName?: boolean
    isPlayNow?: boolean
    autoWidth?: boolean
    autoHeight?: boolean
    radius?: string
    textPlay?: string
  }>(),
  {
    showCoin: true,
    showName: false,
    isPlayNow: true,
    autoWidth: false,
    autoHeight: false,
    radius: '12',
    textPlay: 'Chơi Ngay'
  }
)

const jackpotNumber = computed<number>(() => {
  const keyJackpot: string = props.item.partner_game_id
  const value: Record<string, number> = jackpot.value
  return value[keyJackpot] ?? 0
})

const onClickGame = debounce(() => {
  navigationCheckLoggedInAndOpenGame(props.item)
}, 50)
const getThumbImage = (item: IGameItem) => {
  if (item.tags?.toLowerCase() === 'sự kiện' || item.tags?.toLowerCase() === 'event') {
    return PATH_IMAGES + 'tag-event.svg'
  }
  if (item.tags?.toLowerCase() === 'new') {
    return PATH_IMAGES + 'tag-new.svg'
  }
  if (item.tags?.toLowerCase() === 'live') {
    return PATH_IMAGES + 'tag-live.svg'
  }
  if (item.tags?.toLowerCase() === 'live-stream') {
    return PATH_IMAGES + 'tag-live-stream.svg'
  }
  if (item.tags?.toLowerCase() === 'hot') {
    return PATH_IMAGES + 'tag-hot.svg'
  }
  return ''
}
</script>

<style lang="scss" scoped src="~/assets/scss/components/common/game-item.scss"></style>
